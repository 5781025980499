import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

const Wrapper = styled.section`
  position: relative;
  margin-top: 3em;
  box-sizing: border-box;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  `
  const activeLinkStyle = {
    color: 'white',
  }



const ThirdHero = () => {
    const picture = useStaticQuery(graphql`
    query MyPicture {
     contentfulAsset(title: {eq: "warren-fire-map"}) {
       fluid(maxWidth: 500, quality: 80) {
         src
         srcSet
         aspectRatio
         sizes
       }
     }
   }`
   ) 
    return(   <Wrapper>
                <div className="row tripleTopMargin departmentList">
                      <h1 className="homeH1 bottomMargin">Warren County Fire Departments Needing Volunteers</h1>
                      <div className="column department">
                      <Link to="/alvaton/" activeStyle={activeLinkStyle}>
                          Alvaton
                      </Link>                          
                      </div>
                      <div className="column department">
                      <Link to="/barren-river/" activeStyle={activeLinkStyle}>
                          Barren River
                      </Link>  
                      </div>
                      <div className="column department">
                      <Link to="/browning/" activeStyle={activeLinkStyle}>
                          Browning
                      </Link>  
                      </div>
                      <div className="column department">
                      <Link to="/gott/" activeStyle={activeLinkStyle}>
                          Gott
                      </Link>                        </div>
                      <div className="column department">
                      <Link to="/hadley/" activeStyle={activeLinkStyle}>
                          Hadley
                      </Link>                        </div>
                      <div className="column department">
                      <Link to="/plano/" activeStyle={activeLinkStyle}>
                          Plano
                      </Link>                        </div>                                                                                                              
                      <div className="column department">
                      <Link to="/richardsville/" activeStyle={activeLinkStyle}>
                          Richardsville
                      </Link>                        </div>   
                      <div className="column department">
                      <Link to="/smiths-grove/" activeStyle={activeLinkStyle}>
                          Smiths Grove
                      </Link>                        </div>   
                      <div className="column department">
                      <Link to="/woodburn/" activeStyle={activeLinkStyle}>
                          Woodburn
                      </Link>  
                      </div>   
                                                                                          
                </div>


                <div className="row topMargin">

                <div className="column" style={{maxWidth: '300px'}}></div>

                <div className="column" style={{maxWidth: '400px'}}>
                    <a href="https://images.ctfassets.net/2hx50hxcekhc/1VuXMHVeMrhtrDTalPYIE0/70668b52b5a63fa3759079de32773da2/warren-county-volunteer-fire-districts.png"><Img fluid={picture.contentfulAsset.fluid} placeholderClassName="trey" alt="Warren County KY Fire District Map" /></a>
                </div>

                <div className="column" style={{maxWidth: '300px'}}></div>

                </div>

              </Wrapper>
              
    )
}



export default ThirdHero

