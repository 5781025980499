import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const Wrapper = styled.section`
  position: relative;
  margin-top: 1em;
`

const HomeHero = () => {
   const data = useStaticQuery(graphql`
   query MyQuery {
    contentfulAsset(title: {eq: "fire-fighter"}) {
      fluid(maxWidth: 500, quality: 80) {
        src
        aspectRatio
        srcSet
        sizes
      }
    }
  }
  
    `)



    return(
              <Wrapper>
              <div className="row">
                <div className="column">
  
                  <h1 className="homeH1">Your neighbors need you! </h1>

                  <p className="topMargin">Warren County volunteer fire departments are looking for dedicated volunteers like you. </p>

                  <ul className="topMargin">
                    <li><strong>» No experience required</strong></li>
                    <li><strong>» Free training provided</strong></li>
                    <li><strong>» Make a local difference </strong></li>
                  </ul>
                  
                  <p className="topMargin"></p>

                </div>
                <div className="column">
                  <Img fluid={data.contentfulAsset.fluid} placeholderClassName="trey" />
                </div>   
              </div>
              </Wrapper>
    )
}



export default HomeHero

