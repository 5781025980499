import React from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

const Wrapper = styled.section`
  position: relative;
  margin-top: 3em;
  box-sizing: border-box;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  `

const SecondHero = () => {
   
    return(   <Wrapper>
                <div className="row tripleTopMargin">
                  <div className="column"> 
                      <h1 className="homeH1">Why Join The Volunteer Fire Service?</h1>
                      <p className="topMargin "><strong>Support your local community</strong></p>
                      <p className="topMargin ">Have you always wanted to serve your community?</p>
                      <p className="topMargin ">From fighting fires to responding to medical emergencies and supporting community events or administrative duties, the volunteer fire service is very diverse.  </p>
                      <p className="topMargin ">Warren County volunteer fire departments have many ways you can lend a hand. </p>
                  </div>                
                </div>
              </Wrapper>
    )
}



export default SecondHero

